import React, {useRef, useState}  from 'react';
import Lottie from 'lottie-web';
import animationData from '../animations/continue_game.json';

export const ContinueGame   = ({sessionState, isLoading, okitiJelkuTransfer, updateLevel, totalPoints, totalRemainingTime}) => {
    

    
    const [padezPts, setPadezPts] = useState('pt.');
    const [padezSec, setPadezSec] = useState('sekundi');
    const audioBtn = new Audio("./sfx/nextLevel/Positive Game Win v2.mp3");
    const audioWelcome = new Audio("./sfx/welcome/Slot Game Win v2.mp3");

    const animationContainer = React.createRef();
    const msg_ContinuePlay = `<p key="poeni">Sakupio si ${totalPoints} RSD Xtip BONUSA. </p><p key="vreme">Imaš još ${totalRemainingTime} sekunde.</p>`;
    
  
    const [showMessage, setShowMessage] = useState('hide-msg');
    const [currentMsg, setCurrentMsg] = useState(msg_ContinuePlay);
  
    const restartGame = () => {
      // console.log('restartGame', sessionState.gameState.level);
      document.getElementById('loader-init').style.display = "block";
      updateLevel(1);
      audioBtn.play();
    }
    let lottieAnim;

    const separateNewMessage = (msg) => {
      let newMsg = msg.split ('\n').map ((item, i) => <p key={i}>{item}</p>);
      return newMsg;
    }
    React.useEffect(() => {
        
        // console.log("CONTINUE");
        audioWelcome.play();
        const anim = Lottie.loadAnimation({
          container: animationContainer.current,
          animationData: animationData,
          autoplay: true,
          loop: false
        });
        lottieAnim = anim;
        lottieAnim.stop();
        lottieAnim.addEventListener('loaded_images', function() { lottieAnim.play(); document.getElementById('loader-init').style.display = "none";});  
        if (sessionState.gameState.level === 3) {
          okitiJelkuTransfer();
          updateLevel(sessionState.gameState.level+1);
        }
        
        
        isLoading(false);  
        // let newMsgPoints = String(msg_ContinuePlay.replace("{0000%}", sessionState.gameState.totalPoints +"pt."), msg_ContinuePlay.replace("{0000%}", sessionState.gameState.totalRemainingTime +"pt."));
        // newMsgPoints = separateNewMessage(newMsgPoints);
        // setCurrentMsg(newMsgPoints);
  
        setShowMessage('show-msg');
        
      }, []);

      React.useEffect(() => {
        if (totalRemainingTime?.toString().endsWith('1'))
          setPadezSec('sekundu');
        else if (totalRemainingTime?.toString().endsWith('2') || totalRemainingTime?.toString().endsWith('3') || totalRemainingTime?.toString().endsWith('4'))
          setPadezSec('sekunde');
        else
          setPadezSec('sekundi');
      }, [totalRemainingTime]);
  
    return (
        <div className="continue-game" ref={animationContainer}>

          <div className={'continueSession-msg show-msg'}> <br />  <p key="poeni">Sakupio si {totalPoints} {padezPts} </p><p key="vreme">Imaš još {totalRemainingTime} {padezSec}.</p> </div>            
          
          <div className='btn continue_btn opacity-0' onMouseDown={restartGame}> <img src='./images/btns/NASTAVI.png' /></div>
        </div>
    )
  }